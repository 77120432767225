import React from 'react';
import {graphql} from 'gatsby';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import heroStyles from '../components/blog/BlogHero.module.css';

class BlogPostTemplate extends React.Component {
    render() {
        const post = get(this.props, 'data.contentfulBlogPost');
        const siteTitle = get(this.props, 'data.site.siteMetadata.title');

        return (
            <Layout id="blog">
                <Helmet title={`${post.title} | ${siteTitle}`} />
                <div className={heroStyles.hero}>
                    <Img
                        className={heroStyles.heroImage}
                        alt={post.title}
                        fluid={post.heroImage.fluid}
                    />
                </div>
                <div className="wrapper">
                    <h1 className="section-headline">{post.title}</h1>
                    <p
                        style={{
                            display: 'block',
                        }}>
                        {post.publishDate}
                    </p>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.body.childMarkdownRemark.html,
                        }}
                    />
                </div>
            </Layout>
        );
    }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        contentfulBlogPost(slug: {eq: $slug}) {
            title
            publishDate(formatString: "MMMM Do, YYYY")
            heroImage {
                fluid(quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
            body {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
`;
